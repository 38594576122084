import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHelp } from '../models/app-help.model';


@Injectable()
export class HelpService {
    private readonly http = inject(HttpClient);



    getAll(): Observable<AppHelp[]> {
        const lang = localStorage.getItem('language') || 'pl-PL';
        return this.http.get<AppHelp[]>(`assets/app-help/${lang}.json`);
    }


}

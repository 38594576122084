import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppHelp } from '../../../../models/app-help.model';
import { HelpService } from '../../../../services/help.service';
import { ShareComponent } from '../share/share.component';


@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatIconModule,
        MatButtonModule,
        ShareComponent,
        TranslateModule,
    ],
    providers: [
        HelpService,
    ]
})
export class HelpComponent {
  private readonly appHelpService = inject(HelpService);

  items$: Observable<AppHelp[]>;
  constructor() {
    this.items$ = this.appHelpService.getAll();
  }

}

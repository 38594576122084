import { Component } from '@angular/core';

import { QRCodeComponent } from 'angularx-qrcode';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-share',
  imports: [
    QRCodeComponent,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent {
  public url = window.location.origin;
}
